<template>
  <div class="max">
    <div class="loginBj">
      <div class="button"
        v-if="show">
        <!-- style="background-color: #58a3ef;" -->
        <van-button class="loginbutton"
          round
          type="info"
          style="background-color: white;color:black;"
          @click="shenbao()">自主申报</van-button>
        <van-button class="loginbutton"
          round
          style="margin-top:15px"
          @click="loginTz()">登录</van-button>
      </div>
      <div class="denglu"
        v-if="!sBshow">
        <div class="shuru">
          <input type="text"
            v-model="shenbaofrom.name"
            placeholder="请输入老人姓名" />
          <input type="text"
            v-model="shenbaofrom.idCard"
            placeholder="请输入老人身份证号" />
        </div>
        <van-button class="loginbutton"
          round
          style="margin-top:15px;background-color:rgb(73 101 186);border: 0;color:white;"
          @click="declareCx()">查询</van-button>
        <van-button class="loginbutton"
          round
          style="margin-top:15px;background-color:#91CC75;border: 0;color:white;"
          @click="shenbaoAdd()">登记</van-button>
        <van-button class="loginbutton"
          round
          style="margin-top:15px"
          @click="shenbaoquit()">返 回 </van-button>
      </div>
      <div class="denglu"
        v-if="!show">
        <div class="shuru">
          <input type="text"
            id="user"
            v-model="loginfrom.uname"
            placeholder="请输入登录账号" />
          <input type="password"
            id="password"
            v-model="loginfrom.upwd"
            placeholder="请输入登录密码" />
        </div>
        <!-- 记住密码 -->
        <div class="item"
          style="text-align: right;color: white;">
          <label>记住密码</label>
          <input type="checkbox"
            v-model="loginfrom.remember">
        </div>
        <van-button class="loginbutton"
          round
          style="margin-top:15px"
          @click="login()">登录</van-button>
        <van-button class="loginbutton"
          round
          style="margin-top:15px"
          @click="quit()">返 回 </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { CheckUser, WeGetOldManPage } from "@/api/oldman";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
import { getoldmanToken, setoldmanToken } from "@/utils/auth";
Vue.use(Toast);
export default {
  data () {
    return {
      show: true,
      sBshow: true,
      shenbaofrom: {
        name: "",
        idCard: "",
      },
      loginfrom: {
        //登录提交数据
        uname: "",
        upwd: "",
        uyzm: "",
        remember: ''
      },
    }
  },
  created () {
    console.log(localStorage.getItem("rem_userName"));
    console.log(localStorage.getItem("rem_userPwd"));
    if (localStorage.getItem("rem_userPwd") != "" && localStorage.getItem("rem_userPwd") != null) {
      this.loginfrom.uname = localStorage.getItem("rem_userName");
      this.loginfrom.upwd = localStorage.getItem("rem_userPwd");
      this.loginfrom.remember = true
    }
  },
  methods: {
    loginTz () {
      this.show = false
    },
    quit () {
      this.show = true
    },
    shenbao () {
      this.sBshow = false
    },
    shenbaoquit () {
      this.sBshow = true
    },
    shenbaoAdd () {
      this.$router.push({ name: 'shenbaoShouYe' });
    },
    // 申报查询
    declareCx () {
      console.log(this.shenbaofrom);
      if (this.shenbaofrom.name == "") {
        Toast("老人姓名不能为空");
        return false;
      }
      if (this.shenbaofrom.idCard == "") {
        Toast("老人身份证号不能为空");
        return false;
      }
      WeGetOldManPage(this.shenbaofrom).then((res) => {
        if (res.data.code == 0) {
          var itemmark = JSON.stringify(this.shenbaofrom);
          this.$router.push({ name: 'shenbaoAdd', params: { id: itemmark } })
        } else if (res.data.code == 1) {
          Toast.fail(res.data.msg);
        }
      });


    },
    login () {
      //登录
      if (this.loginfrom.uname == "") {
        Toast("登录账号不能为空");
        return false;
      }
      if (this.loginfrom.upwd == "") {
        Toast("登录密码不能为空");
        return false;
      }
      console.log(this.loginfrom.uname + "");
      CheckUser(this.loginfrom).then((res) => {
        if (res.data.code == 0) {
          Toast.success("登录成功");
          console.log(res.data.data.token);
          setoldmanToken(res.data.data.token);
          this.$router.push({ name: 'lrdjShouYe' });
          // 储存登录信息
          this.setUserInfo()
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 储存表单信息
    setUserInfo: function () {
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (this.loginfrom.remember) {
        localStorage.setItem("rem_userName", this.loginfrom.uname);
        localStorage.setItem("rem_userPwd", this.loginfrom.upwd);
      } else {
        localStorage.removeItem("rem_userName");
        localStorage.removeItem("rem_userPwd");
      }
    },
  }
}
</script>
<style scoped>
.max {
  position: relative;
  width: 100%;
  height: 100%;
}
.shuru input {
  border-radius: 50px;
  width: 88%;
  height: 50px;
  background: #e6e6e6;
  margin: 15px 0px;
  padding-left: 35px;
  color: #000;
  border: none;
  box-shadow: 1px 1px 1px #e6e6e6;
}
.denglu {
  position: absolute;
  bottom: 10%;
  width: 75%;
  left: 12%;
}
.loginBj {
  background: url('https://lycqljdapi.dexian.ren//upload/images/2021/9/39324d04-275.png')
    no-repeat;
  height: 100%;
  background-size: 100% 100%;
}
.button {
  position: absolute;
  bottom: 10%;
  width: 75%;
  left: 12%;
}
.loginbutton {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
}
</style>